<template>
  <div
    class="form-login d-flex justify-content-center align-items-center flex-column"
  >
    <div class="logo">
      <img src="../../assets/img/Logo-CDEA.png" alt="" />
    </div>
    <div class="w-100 mb-3 px-5">
      <form v-on:submit.prevent="submitLogin(idParam)">
        <div class="line-text"><span>パスワードを忘れた方</span></div>
        <div v-if="!issuccess" class="d-flex flex-column form-input">
          <input
            v-model="email"
            type="email"
            name="email"
            id="email"
            placeholder="登録したメールアドレスを入力してください"
            required
          />
        </div>
        <div
          v-if="issuccess"
          class="d-flex justify-content-center align-items-center flex-column h6"
        >
          <span
            >メールはあなたが入力されたメールアドレスに送信されました。</span
          >
          <span>ご確認ください。</span>
        </div>
        <div v-if="!issuccess" class="btn-send">
          <button v-on:click.prevent="sendmail()">
            パスワード再発行メールを送信
          </button>
        </div>
      </form>
    </div>
    <div class="text-center">
      <span
        ><a href="\" style="color: black">よくあるご質問</a> &ensp;
        <a href="\" style="color: black">お問い合わせ</a> &ensp;
        <a href="\" style="color: black">会社概要</a> &ensp;
        <a href="\" style="color: black">プライバシーポリシー</a> &ensp;
        <a href="\" style="color: black">利用規約</a> &ensp;
        <a href="\" style="color: black">特定商取引法</a>
        に基づく表記</span
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ForgotPasswordCDEA",
  data() {
    return {
      email: "",
      issuccess: false,
    };
  },
  computed: {
    ...mapGetters(["message", "success", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.issuccess = true;
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.issuccess = false;
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  created() {
    this.$store.commit("set", ["error", false]);
  },
  methods: {
    ...mapActions({ sendMailForgotPassword: "sendMailForgotPassword" }),
    async sendmail() {
      if (this.email == "") {
        this.$toasted.error("メールフィールドは必須です。");
      } else {
        let emailData = { email: this.email };
        await this.sendMailForgotPassword(emailData);
      }
    },
  },
};
</script>
